import React, { useRef, useEffect } from "react";
import { philosophy } from "../../../data/pages/about";
import { GatsbyImage } from "gatsby-plugin-image";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./philosophy.scss";
import CTA from "../../Button/index";
import { PhilosophyProps } from "../types/teamMemberTypes";
gsap.registerPlugin(ScrollTrigger);

const RiskPhilosophy: React.FC<PhilosophyProps> = ({ images }) => {
  const indexRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const indexChildren = [...(indexRef.current?.children || [])];

    if (indexRef) {
      indexChildren.forEach((card) => {
        const image = card.querySelector(
          ".pcm-philosophy__card__image"
        ) as HTMLDivElement;
        const realImage = card.querySelector(
          ".gatsby-image-wrapper"
        ) as HTMLDivElement;
        const text = card.querySelector(
          ".pcm-philosophy__card__text"
        ) as HTMLDivElement;

        gsap.fromTo(
          realImage,
          { y: -80 },
          {
            y: 0,
            scrollTrigger: {
              scrub: true,
              trigger: card,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );

        gsap.fromTo(
          image,
          {
            clipPath: "inset(20%)",
            "--aspect": "150%",
            visibility: "hidden",
            duration: 0.5,
            opacity: 0,
            ease: "power1.out",
          },
          {
            duration: 0.5,
            clipPath: "inset(0%)",
            "--aspect": "100%",
            visibility: "inherit",
            opacity: 1,
            scrollTrigger: {
              trigger: card,
              start: "top center",
              end: "bottom top",
            },
          }
        );
        gsap.fromTo(
          realImage,
          {
            width: 700,
            duration: 0.75,
            ease: "power1.out",
            scale: 1.25,
          },
          {
            duration: 0.75,
            scale: 1,
            width: 420,
            scrollTrigger: {
              trigger: card,
              start: "top center",
              end: "bottom top",
            },
          }
        );
        gsap.fromTo(
          text,
          {
            opacity: 0,
            visibility: "hidden",
            duration: 0.5,
            ease: "power1.out",
          },
          {
            duration: 0.5,
            opacity: 1,
            visibility: "inherit",
            scrollTrigger: {
              trigger: card,
              start: "top center",
              end: "bottom bottom",
            },
          }
        );
      });
    }
  }, []);
  return (
    <section id="pcm-about-philosophy" className="pcm-philosophy">
      <div className="pcm-philosophy__inner">
        <div className="pcm-philosophy__header">
          <h5>Risk Smart: Balancing Safety and Growth</h5>
          <h2>Our Risk Philosophy</h2>
          <p>
            We have a unique approach to risk management that focuses on
            explicit loss limits and long-term returns.
          </p>
        </div>
        <div ref={indexRef} className="pcm-philosophy__index">
          {philosophy.map((card) => {
            const { image, text, index } = card;
            return (
              <div key={image} className="pcm-philosophy__card">
                <div className="pcm-philosophy__card__image">
                  <div className="pcm-philosophy__card__image__wrapper">
                    <GatsbyImage alt={image} image={images[image]} />
                  </div>
                </div>
                <div className="pcm-philosophy__card__text">
                  <h2>{index}.</h2>
                  <p>{text}</p>
                </div>
              </div>
            );
          })}
          <div ref={ctaRef} className="pcm-philosophy__index__button">
            <h2>Learn about our strategy.</h2>
            <div className="pcm-philosophy__index__button__wrapper">
              <CTA theme="green" to="/strategy">
                Our strategy
              </CTA>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RiskPhilosophy;
