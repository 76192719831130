import React, { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classNames from "classnames";
import "../styles/pages/about.scss";
import OurStory from "../components/about/OurStory";
import TeamMember from "../components/about/TeamMember";
import RiskPhilosophy from "../components/about/Philosophy";
import { Link } from "../components/links";
import { founders, partners } from "../data/pages/about";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { AboutProps } from "../types/about";
import CTA from "../components/Button";
gsap.registerPlugin(ScrollTrigger);

const About: React.FC<AboutProps> = ({ data }) => {
  const heroImageRef = useRef<HTMLDivElement>(null);
  const missionImageRef = useRef<HTMLDivElement>(null);

  const {
    team: { edges: teamImages },
    philosophy: { edges: philosophyImages },
  } = data;

  const sortedTeamImages = assetArrayToObject({
    useGatsbyImage: true,
    images: teamImages,
  });

  const sortedPhilosophyImages = assetArrayToObject({
    useGatsbyImage: true,
    images: philosophyImages,
  });

  useEffect(() => {
    if (heroImageRef.current) {
      gsap.fromTo(
        heroImageRef.current,
        { y: -300 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".pcm-about__hero",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    if (missionImageRef.current) {
      gsap.fromTo(
        missionImageRef.current,
        { y: -200 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ".pcm-about__our-mission",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  return (
    <div className="pcm-about">
      <section className="pcm-about__hero">
        <div ref={heroImageRef} className="pcm-about__hero__image-wrapper">
          <StaticImage
            src="../images/about/aboutHero.jpg"
            alt="hero background"
            layout="fullWidth"
            quality={80}
            formats={["auto", "webp", "avif"]}
            style={{ height: "100%", width: "100%" }}
            loading="eager"
          />
        </div>
        <div className="pcm-about__hero__inner">
          <div className="pcm-about__hero__text">
            <h1>About us</h1>
            <h2>
              Secure Your Financial Future with Innovative, Stress-Free
              Investment Solutions
            </h2>
            <h3>
              Welcome to Preservation Capital, where innovation meets security
              in retirement investing. Founded by experienced veterans Jerry
              Chafkin and Matt Connors, our firm specializes in creating
              low-cost, high-impact investment strategies that safeguard and
              grow your retirement savings. Our collaboration with Yayati Asset Management brings
              quantitative precision to our unique Income+Growth Account,
              reducing risk and amplifying returns. Join us to explore
              investment solutions that bring peace of mind and prosperity to
              your retirement journey.
            </h3>
            <CTA isAnchor theme="green" to="#pcm-about-our-story">
              Explore more
            </CTA>
          </div>
        </div>
      </section>
      <OurStory />
      <section id="pcm-about-team" className="pcm-about__team">
        <div className="pcm-about__team__inner">
          <div className="pcm-about__team__section">
            <div className="pcm-about__team__header">
              <h5>Veterans of the Investment Management industry</h5>
              <h2>Our Founders</h2>
              <p>
                Get to know the founders and co-portfolio managers behind
                Preservation Capital Management.
              </p>
            </div>
            <div className="pcm-about__team__section__index--founders">
              {founders.map((member) => {
                const { image } = member;
                const memberImage = sortedTeamImages[image];
                return (
                  <TeamMember
                    founder={true}
                    key={member.name}
                    data={member}
                    image={memberImage}
                  />
                );
              })}
            </div>
          </div>
          <div className="pcm-about__team__section">
            <div className="pcm-about__team__header">
              <h5>Our Partners</h5>
              <h2>Partnered with Yayati Asset Management</h2>
              <p>Our team of research analysts and traders.</p>
            </div>
            <div className="pcm-about__team__section__index">
              {partners.map((member) => {
                const { image } = member;
                const memberImage = sortedTeamImages[image];
                return (
                  <TeamMember
                    key={member.name}
                    data={member}
                    image={memberImage}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section id="pcm-about-our-mission" className="pcm-about__our-mission">
        <div className="pcm-about__our-mission__inner">
          <div className="pcm-about__our-mission__text">
            <h5>
              Invest with Confidence
            </h5>
            <h2>Our Mission</h2>
            <p>
              To design and deliver low-cost, innovative investment solutions
              for the clients of financial advisors that make investing and
              staying invested less stressful by structuring and communicating
              explicit loss limits while still providing investors with
              competitive long-term returns.
            </p>
          </div>
          <div className="pcm-about__our-mission__image">
            <div
              ref={missionImageRef}
              className="pcm-about__our-mission__image__wrapper"
            >
              <StaticImage
                src="../images/about/fruitTree.jpg"
                alt="our mission"
                layout="fullWidth"
                quality={80}
                formats={["auto", "webp", "avif"]}
                style={{ height: "640px", width: "616px" }}
              />
            </div>
          </div>
        </div>
      </section>
      <RiskPhilosophy images={sortedPhilosophyImages} />
    </div>
  );
};

export const query = graphql`
  query {
    team: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "about/team" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 486, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
    philosophy: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "about/philosophy" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 420, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;

export default About;
