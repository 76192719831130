type ImageNode = {
  name: string;
  publicURL: string;
  childImageSharp?: {
    gatsbyImageData?: any; // Replace 'any' with the specific type if you have it
    fixed?: {
      src: string;
    };
  };
};

type ImageEdge = {
  node: ImageNode;
};

type ImagesInput =
  | {
      edges?: ImageEdge[];
    }
  | ImageEdge[];

const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );

export const assetArrayToObject = ({
  images,
  convertToCamel = true,
  useGatsbyImage = false,
}: {
  images: ImagesInput;
  convertToCamel?: boolean;
  useGatsbyImage?: boolean;
}): Record<string, any> => {
  let _images: ImageEdge[]; // Declare the variable without initializing
  if (Array.isArray(images)) {
    _images = images; // Assign directly if it's an array
  } else if (images && images.edges) {
    _images = images.edges; // Assign edges if available
  } else {
    _images = []; // Fallback to an empty array
  }

  return _images.reduce<Record<string, any>>((acc, { node }) => {
    const name = convertToCamel ? snakeToCamel(node.name) : node.name;
    acc[name] =
      useGatsbyImage && node.childImageSharp
        ? node.childImageSharp.gatsbyImageData
        : node.publicURL;
    return acc;
  }, {});
};
