import React from "react";
import "./team-member.scss";
import { Link } from "../../links";
import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { TeamMemberProps } from "../types/teamMemberTypes";

const TeamMember: React.FC<TeamMemberProps> = ({
  image,
  data,
  founder,
  index,
}) => {
  const { name, role, description, link, linkedin } = data;

  const teamClasses = classNames("pcm-team-member", {
    "pcm-team-member--founder": founder,
  });
  return (
    <div className={teamClasses}>
      <GatsbyImage alt={`${name}-image`} image={image} />
      <div className="pcm-team-member__text">
        <p className="pcm-team-member__name">{name}</p>
        <p className="pcm-team-member__role">{role}</p>
        <ul className="pcm-team-member__description">
          {description.map((text: string) => (
            <li>{text}</li>
          ))}
        </ul>
        <div className="pcm-team-member__links">
          {linkedin && (
            <Link isOutbound to={linkedin}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--black)"
                  d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"
                />
              </svg>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamMember;
