export interface PhilosophySegment {
  text: string;
  image: string;
  index: number;
}

export interface TeamMember {
  name: string;
  role: string;
  description: string[];
  link?: string;
  linkedin?: string | null;
  image: string;
}

export const ourStory: string[] = [
  "Preservation Capital was started by two successful veterans of the Investment Management industry, Jerry Chafkin and Matt Connors, who both retired in 2021 to focus on investing their own retirement savings and learned that all of the most commonly recommended income solutions that provided adequate yield came with risks and uncertainties that were not appreciated or well understood by investors in retirement.",
  "As retirees themselves, Jerry and Matt recognized that while maximizing their retirement income was important, it was not more important than protecting their retirement savings and certainly not more important than minimizing the stress of market gyrations that would otherwise interfere with their enjoying their “retirement.”",
  "Given their emphasis on liquidity, the strategy they developed turned out to have capacity beyond what they needed for themselves so they decided to also make it available to the clients of a dozen or so advisors that they knew and respected.",
  "By partnering with quantitative researchers at Yayati to provide simulations and performance attribution to help develop the Multi-Strategy Income+Growth Account, it became clear that the structural inefficiency of option markets provides opportunities across a broad range of investment solutions to reduce uncertainty and/or enhance return.",
];

export const founders: TeamMember[] = [
  {
    name: "Jeremiah Chafkin",
    role: "Co-Founder & Chief Investment Officer",
    description: [
      "30+ years of investment experience.",
      "Chief Investment Officer at AssetMark Financial. As the CIO for this $70 billion investment consulting and technology platform for independent financial advisors, Mr. Chafkin evaluated third party investment strategies, as well as designed proprietary investment strategies, including retirement solutions.",
      "CEO, Portfolio Manager & Investment Committee Chair at AlphaSimplex –a $4 billion managed futures and active risk manager.",
      "CEO of a holding company for a variety of asset managers in the U.S. & Asia.",
      "Head of Investment Advice & Research and Head of Proprietary & Third Party Research at Charles Schwab.",
      "Investment Manager at Bankers Trust. Mr. Chafkin managed a $230 billion structured investment management business including fixed income, stock and bond indexing, enhanced indexing, for institutional clients.",
    ],
    link: "/team/jerry",
    linkedin: "https://www.linkedin.com/in/jeremiah-chafkin-aa177196/",
    image: "Jerry",
  },
  {
    name: "Matthew Connors III",
    role: "Co-Founder & Head of Product Design",
    description: [
      "30+ years of investment experience.",
      "Managing Director and Client Portfolio Manager at AlphaSimplex, a $7 billion manager of liquid alternatives and active volatility management solutions. As the Client Portfolio Manager, Mr. Connors focused on positioning products and designing portfolio allocations, inclusive of proprietary solutions, to meet the overall needs and objectives of financial intermediaries and institutions.",
      "Managing Director at ProFunds Group. Mr. Connors was part of the senior management team and grew AUM from $1 billion to $36 billion, making the firm one of the nation's largest providers of ETFs.",
      "Managing Director and Head of Investment Distribution at Deutsche Bank Asset Management. Mr. Connors was responsible for fund design, innovation and product management, rationalizing 4 mutual fund families (Flag Funds, Morgan Grenfell, Bankers Trust, and Deutsche Bank), with combined assets of $60 billion into a single profitable global brand.",
      "Managing Director at Alex Brown & Sons Private Bank Asset Management Group where Mr. Connors grew Flag Funds AUM from $1 billion to $20 billion and created its first institutional share class. Responsible for sales, marketing and product development.",
    ],
    link: "/team/matt",
    linkedin: null,
    image: "Matthew",
  },
];

export const partners: TeamMember[] = [
  {
    name: "Rajeev Sharan",
    role: "Founder and Head of Investment & Quantitative Analytics",
    description: [
      "Portfolio Manager @ Savos Investments (AssetMark)",
      "Guest Lecturer @ UCLA, Finance",
      "Director of Risk Management @ Aspiriant",
      "AVP @ Deutsche Bank, Derivative Structuring & Risk Analytics",
      "Head of Analytics @ Dun & Bradstreet",
      "Consultant @ Fractal Analytics",
    ],
    linkedin: "https://www.linkedin.com/in/rajeev-r-raj-sharan-5a741614/",
    image: "rajeev",
  },
  {
    name: "Joon Hyon",
    role: "Head of Trading",
    description: [
      "Manager @ AssetMark, Trading",
      "Investment Analyst @ Genworth Financial",
      "Investment Specialist @ GE Private Asset Management",
      "Research Analyst @ Centurion Capital Group",
    ],
    linkedin: "https://www.linkedin.com/in/joon-hyon-7544807/",
    image: "joon",
  },
  {
    name: "Milan Patel",
    role: "Head of Business Development",
    description: [
      "Co-Founder @ Yayati",
      "Principal @ Patton Logistics",
      "VP, Multi-Asset Solutions @ Goldman Sachs",
      "Advisory Board Member @ Stevens Institute of Technology",
    ],
    linkedin: "https://www.linkedin.com/in/milanrajpatel/",
    image: "milan",
  },
];

export const philosophy: PhilosophySegment[] = [
  {
    text: "We define risk differently from most investment managers who think of risk as “volatility” (a statistical measure of short-term variability in returns relative to a long term average). We think of risk the same way as most individual investors do i.e., the worst decline in value an investment has experienced in the past or is likely to experience in the future.",
    image: "dinner",
    index: 1,
  },
  {
    text: "To be clear, the value of stocks and other markets historically have eventually recovered over time from any deep declines in market value, but the same is not necessarily true of the investment holdings in an individual investor’s portfolio. The reason is that an investor’s fear of losing their retirement savings may understandably make them feel compelled to sell their investments in order to protect the remaining value of their nest egg even if that means locking in losses they have already suffered.",
    image: "robeWine",
    index: 2,
  },
  {
    text: "After deep drops in the value of their investment portfolios, investors frequently tell themselves they will buy back into the market once it has stabilized but because there are no readily available measures of probability of loss. Investors typically wait until markets have fully recovered before reinvesting, missing most of the price recovery necessary to build back their savings.",
    image: "flowers",
    index: 3,
  },
  {
    text: "The nature of investing is that market value will successively rise and fall over time. One way to earn higher long-term returns is to lose less during the periods with the most extreme market downturns. This is possible by buying a cap on potential losses (aka put options). Owning a cap on the severity of potential losses also makes it easier for investors to tune out the noise from news media to know that fear sells newspapers and attracts eyeballs for television, making it difficult for investors to stay disciplined and invested.",
    image: "plane1",
    index: 4,
  },
  {
    text: "Finally the cost of buying a cap on potential losses can be reduced and sometimes even offset by selling a cap on the potential upside appreciation of some of an investor’s assets. Unlimited participation in the occasional extreme short-term jump in asset prices is only important if an investor also fully participated in the extreme drop in asset prices that preceded it our style is to limit participation in extreme, short-term price moves regardless of whether a move is up or down, relying instead on long term price appreciation accompanying long term economic growth.",
    image: "buying",
    index: 7,
  },
  {
    text: "The benefits of options are that they provide an explicit limit on how far the value of an investment can fall, insulating the value of an individual's portfolio from the value of the stock market from the value of an individual’s portfolio that owns put options.",
    image: "deck",
    index: 6,
  },
  {
    text: "While options are a very useful tool to manage risk, it remains a truism of investing that the most cost effective form of risk management is diversification, especially when that is extended to include strategy as well as asset classes and when combined in the context of how each is likely to behave in different macro-economic environments and market scenarios.",
    image: "boatWine",
    index: 5,
  },
];
