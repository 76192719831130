import React, { useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./ourstory.scss";
import { ourStory } from "../../../data/pages/about";
import { useCheckScreenSize } from "../../../lib/checkScreenSize";
gsap.registerPlugin(ScrollTrigger);

const OurStory: React.FC = () => {
  const timelineLineRef = useRef<HTMLSpanElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    gsap.fromTo(
      timelineLineRef.current,
      {
        height: 0,
      },
      {
        height: "100%",
        scrollTrigger: {
          trigger: timelineRef.current,
          scrub: true,
          start: "top bottom",
          end: "bottom top",
        },
      }
    );

    const contentCards = [...(contentRef.current?.children || [])];

    contentCards.forEach((card: Element, index: number) => {
      const tl1 = gsap.timeline();

      const horizontalLine = card.querySelectorAll("span");
      const text = card.querySelectorAll("p");
      const ball = card.querySelectorAll("div");
      gsap.fromTo(
        text,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          scrollTrigger: {
            trigger: card,
            scrub: true,
            start: "top center",
            end: "bottom top",
          },
        }
      );
      tl1.fromTo(
        horizontalLine,
        {
          width: 0,
        },
        {
          width: `${35 - index * 8}%`,
          scrollTrigger: {
            trigger: card,
            scrub: true,
            start: "top center",
            end: "bottom top",
          },
        }
      );
      tl1.fromTo(
        ball,
        {
          width: 0,
          height: 0,
        },
        {
          width: 20,
          height: 20,
          scrollTrigger: {
            trigger: card,
            scrub: true,
            start: "top center",
            end: "bottom top",
          },
        }
      );
    });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  return (
    <section id="pcm-about-our-story" className="pcm-our-story">
      <div className="pcm-our-story__inner">
        <div className="pcm-our-story__header">
          <h5>Timeline</h5>
          <h2>Our Story</h2>
        </div>
        <div ref={timelineRef} className="pcm-our-story__timeline">
          <span
            className="pcm-our-story__timeline__line"
            ref={timelineLineRef}
          ></span>
          <div ref={contentRef} className="pcm-our-story__timeline__content">
            {ourStory.map((card) => {
              return (
                <div key={card} className="pcm-our-story__timeline__card">
                  <span></span>
                  <div></div>
                  <p>{card}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
